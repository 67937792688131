
const routes: Array<any> = [
  {
    path: '/',
    wrapper: 'default',
    module: import(/* webpackChunkName: "module-main" */'@module/main')
  },
  {
    path: '*',
    wrapper: 'default',
    module: import(/* webpackChunkName: "module-offer" */'@module/offer')
  },
];

export default routes;
