var Events = /** @class */ (function () {
    function Events() {
        this.events = {};
    }
    Events.prototype.emit = function (eventName, data) {
        if (!eventName) {
            throw new Error('EventName is not set');
        }
        if (!(eventName in this.events)) {
            return void 0;
        }
        var handlers = this.events[eventName];
        for (var index in handlers) {
            handlers[index].call(null, data);
        }
    };
    Events.prototype.on = function (eventName, callback) {
        if (!eventName) {
            throw new Error('EventName is not set');
        }
        if (!this.events[eventName]) {
            this.events[eventName] = [];
        }
        this.events[eventName].push(callback);
    };
    Events.prototype.off = function (eventName, callback) {
        if (!eventName) {
            throw new Error('EventName is not set');
        }
        if (!(eventName in this.events)) {
            return void 0;
        }
        this.events[eventName] = this.events[eventName].filter(function (handler) { return handler !== callback; });
    };
    return Events;
}());
export default Events;
