"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Dialog", {
  enumerable: true,
  get: function get() {
    return _view["default"];
  }
});
Object.defineProperty(exports, "closeDialog", {
  enumerable: true,
  get: function get() {
    return _commands.closeDialog;
  }
});
Object.defineProperty(exports, "name", {
  enumerable: true,
  get: function get() {
    return _slice.name;
  }
});
Object.defineProperty(exports, "openDialog", {
  enumerable: true,
  get: function get() {
    return _commands.openDialog;
  }
});
Object.defineProperty(exports, "reducer", {
  enumerable: true,
  get: function get() {
    return _slice.reducer;
  }
});
Object.defineProperty(exports, "resetStateAction", {
  enumerable: true,
  get: function get() {
    return _slice.resetStateAction;
  }
});

var _view = _interopRequireDefault(require("./view"));

var _slice = require("./store/slice");

var _commands = require("./store/commands");