"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectName = exports.selectIsOpen = exports.selectData = exports.resetStateAction = exports.reducer = exports.openDialogAction = exports.name = exports.closeDialogAction = void 0;

var _toolkit = require("@reduxjs/toolkit");

var REDUCER_NAME = 'package/dialog';
var initialState = {
  isOpen: false,
  name: null,
  data: null
};
var slice = (0, _toolkit.createSlice)({
  name: REDUCER_NAME,
  initialState: initialState,
  reducers: {
    resetStateAction: function resetStateAction(state) {
      state['isOpen'] = false;
      state['name'] = null;
      state['data'] = null;
    },
    openDialogAction: function openDialogAction(state, _ref) {
      var payload = _ref.payload;
      state['isOpen'] = true;
      state['name'] = payload['name'];
      state['data'] = payload['data'];
    },
    closeDialogAction: function closeDialogAction(state) {
      state['isOpen'] = false;
      state['name'] = null;
      state['data'] = null;
    }
  }
});
var _slice$actions = slice['actions'],
    resetStateAction = _slice$actions.resetStateAction,
    openDialogAction = _slice$actions.openDialogAction,
    closeDialogAction = _slice$actions.closeDialogAction;
exports.closeDialogAction = closeDialogAction;
exports.openDialogAction = openDialogAction;
exports.resetStateAction = resetStateAction;

var selectName = function selectName(state) {
  return state[REDUCER_NAME]['name'];
};

exports.selectName = selectName;

var selectIsOpen = function selectIsOpen(state) {
  return state[REDUCER_NAME]['isOpen'];
};

exports.selectIsOpen = selectIsOpen;

var selectData = function selectData(state) {
  return state[REDUCER_NAME]['data'];
};

exports.selectData = selectData;
var name = slice['name'];
exports.name = name;
var reducer = slice['reducer'];
exports.reducer = reducer;