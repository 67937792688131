"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/slicedToArray"));

var _react = require("react");

function useLoad(module) {
  var _useState = (0, _react.useState)(function () {
    return null;
  }),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      component = _useState2[0],
      setComponent = _useState2[1];

  (0, _react.useLayoutEffect)(function () {
    module.then(function (instance) {
      return setComponent(function () {
        return instance['default'];
      });
    });
  }, [module]);
  return component;
}

var _default = useLoad;
exports["default"] = _default;