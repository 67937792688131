
const navigate = [
  {
    title: 'Главная',
    path: '/',
  },
  {
    title: 'Оферта',
    path: '/offer',
  },
];

export default navigate;
