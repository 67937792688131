var withURLSearchParams = function (query) {
    var searchURL = new URLSearchParams(query);
    var params = {};
    for (var _i = 0, searchURL_1 = searchURL; _i < searchURL_1.length; _i++) {
        var param = searchURL_1[_i];
        if (!!param[1]) {
            var paramValue = param[1];
            if (/^\d{1,16}$/.test(paramValue)) {
                paramValue = Number(paramValue);
            }
            else if (/^\d+$/.test(paramValue)) {
                paramValue = Number(paramValue);
            }
            else if (/^([0])+|([0]\w)+$/.test(paramValue)) {
                paramValue = String(paramValue);
            }
            else if (/^(true)$/.test(paramValue)) {
                paramValue = true;
            }
            else if (/^(false)$/.test(paramValue)) {
                paramValue = false;
            }
            params[param[0]] = paramValue;
        }
    }
    return params;
};
var withoutURLSearchParams = function (query) {
    var search = query.substring(1);
    return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
};
export var toObject = function (query, options) {
    if (options === void 0) { options = { encoding: true }; }
    if (!query) {
        return {};
    }
    if (options['encoding']) {
        return withURLSearchParams(query);
    }
    return withoutURLSearchParams(query);
};
export var toQuery = function (object) {
    var query = {};
    for (var key in object) {
        if (object.hasOwnProperty(key)) {
            var value = object[key];
            if (!!value) {
                query[key] = value;
            }
        }
    }
    var searchURL = new URLSearchParams(query);
    return '?' + decodeURI(searchURL.toString());
};
