
import Push from '@package/push';
import { Application, Config, Router, Wrapper } from '@library/app';
import { config as requestConfig } from '@package/request';

import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';

import createStore from './store';
import navigate from './configs/navigate';
import publicRoutes from './configs/routes/public';

import './styles/index.scss';


(async () => {
  try {
    requestConfig({
      baseUrl: process.env['REACT_APP_GATEWAY_API'],
    });

    const app = new Application(new Config({
      navigate,
    }));

    app.addLoader(import('./Loader'));

    app.addRouter(new Router(publicRoutes));

    app.addWrapper('default', new Wrapper(import('@wrapper/default')));

    const store = await createStore(app);
    const selector = document.querySelector('#root');

    if ( ! selector) {
      throw new Error('selector is null');
    }

    const root = ReactDOM.createRoot(selector);
    const AppView = app.createView();

    root.render(
      <Provider store={store}>
        <AppView />
        <Push />
      </Provider>
    );
  }
  catch(error) {

    console.log(error);
  }
})();
